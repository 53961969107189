import { graphql } from 'gatsby'
import { News } from '../pages/news'

export default News

export const query = graphql`
  query NewsDetailQuery {
    cms {
      entry(slug: "news") {
        ... on cms_Entry_Pages_PageNews {
          id
          featured_blog {
            ... on cms_Entry_News_News {
              id
              title
              date
              custom_date
              preview_text
              video_source {
                label
                value
              }
              vimeo_id
              youtube_id
              copy
              related_article {
                id
              }
              article_type {
                label
                value
              }
              title_image {
                path
                ... on cms_Asset_Assets {
                  id
                  alt
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(width: 1600, height: 880, formats: [AUTO, WEBP])
                    }
                    absolutePath
                  }
                }
              }
            }
          }
          featured_content {
            ... on cms_Entry_News_News {
              id
              title
              custom_date
              date
              preview_text
              video_source {
                label
                value
              }
              article_type {
                label
                value
              }
              vimeo_id
              youtube_id
              copy
              related_article {
                id
              }
              title_image {
                path
                ... on cms_Asset_Assets {
                  id
                  alt
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(width: 1600, height: 880, formats: [AUTO, WEBP])
                    }
                    absolutePath
                  }
                }
              }
            }
          }
        }
      }
      allNews: entries(collection: "news",  sort: "custom_date desc") {
        data {
          id
          ... on cms_Entry_News_News {
            id
            date
            title
            slug
            date
            custom_date
            preview_text
            video_source {
              label
              value
            }
            article_type {
              label
              value
            }
            vimeo_id
            youtube_id
            iframe
            copy
            related_article {
              id
              ... on cms_Entry_News_News {
                title
                preview_text
                vimeo_id
                youtube_id
                copy
                video_source {
                  label
                  value
                }
                title_image {
                  path
                  ... on cms_Asset_Assets {
                    id
                    alt
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(width: 2560, height: 1440, formats: [AUTO, WEBP])
                      }
                      absolutePath
                    }
                  }
                }
              }
            }
            title_image {
              path
              ... on cms_Asset_Assets {
                id
                alt
                imageFile {
                  childImageSharp {
                    gatsbyImageData(width: 2560, height: 1440, formats: [AUTO, WEBP])
                  }
                  absolutePath
                }
              }
            }
          }
        }
      }
    }
  }
`;
